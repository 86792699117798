import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		orders: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/orders')
				.then(({ data }) => {
					commit(types.STORE_ORDERS, data)
					return data;
				})
		},
		
		fetchById({ commit }, { id }) {
			return axios.get(`/orders/view/${id}`).then(({ data }) => data)
		},
	},

	mutations: {
		[types.STORE_ORDERS](state, orders) {
			state.orders = orders;
		}
	},

	getters: {
		getOrderById: state => id => state.orders.find(c => c.id === id)
	}
}