import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		aboutUs: [],
	},

	actions: {
		fetchAll({ commit }, { acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get('/aboutus', configs).then(({ data }) => {
				commit(types.STORE_ABOUT_US, data);
				return data;
			})
		},
		
		create({ commit }, { about, mission, vision, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post('/aboutus', { about, mission, vision }, configs);
		}
	},

	mutations: {
		[types.STORE_ABOUT_US](state, aboutUs) {
			state.aboutUs = aboutUs;
		}
	}
}