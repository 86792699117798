<template>
	<v-dialog
		v-model="localeValue"
		max-width="915"
		:persistent="uploadLoading || removeLoading"
		style="z-index: 1300"
	>
		<v-form ref="form" @submit.prevent="upload">
			<v-card class="fileManager rounded-medium">
				<v-card-title class="primary--text text-h5">
					{{$t('components.file-manger')}}
				</v-card-title>
				<v-card-text class="pb-2">
					<v-tabs v-model="activeTab" vertical height="80">
						<!-- images -->
						<v-tab>
							{{$t('components.fileTypes.images')}}
						</v-tab>

						<v-tab-item class="py-2 ps-8">
							<!-- loader -->
							<div v-if="loading" class="text-center" style="height: 100%">
								<v-progress-circular color="primary" indeterminate />
							</div>

							<template v-else>
								<!-- images -->
								<v-layout wrap align-center class="mt-2">
									<v-flex
										v-for="(image, index) in mediumImages"
										:key="index"
										shrink
										class="pe-2 pb-4"
									>
										<v-img
											v-ripple
											:src="image"
											max-width="180"
											max-height="120"
											class="fileManager__image rounded-medium elevation-2"
											:class="{ 'fileManager__image--selected': selectedImage === image }"
											contain
											lazy-src="@/assets/placeholder-image.png"
											@click="selectedImage = image"
											@dblclick="previewDialog = true"
										/>
									</v-flex>
								</v-layout>
							</template>
						</v-tab-item>
						
						<!-- upload -->
						<v-tab>
							{{$t('components.upload')}}
						</v-tab>

						<v-tab-item class="py-2 ps-8">
							<!-- drop image -->
							<bee-drop-img
								v-model="uploadImage"
								radius="11"
								border-width="2"
								:border-color="isSubmitted && !uploadImage ? '#f00' : '#ccc'"
								width="65%"
								dashed
								height="250px"
								blurable
								clearable
								:hide-image-name="false"
								class="mx-auto"
							/>
						</v-tab-item>
					</v-tabs>
				</v-card-text>

				<v-card-actions class="px-4 pb-3">
					<!-- filter chips -->
					<v-fade-transition>
						<div
							v-if="activeTab === 0"
							class="d-flex align-center"
						>
							<span class="font-weight-medium primary--text text-body-2 mx-4">
								{{$t('components.size-to-use')}}:
							</span>
							<v-chip-group
								v-model="imageSize"
								active-class="primary"
								mandatory
							>
								<v-chip
									v-for="filter in imagesFilters"
									:key="filter.id"
									:value="filter.id"
									small
								>
									{{filter.name}}
								</v-chip>
							</v-chip-group>

							<span class="font-weight-medium primary--text text-body-2 mx-4">
								{{$t('components.size')}}:
							</span>
							<span>{{imageSizeOnUse}}</span>
						</div>
					</v-fade-transition>

					<v-spacer/>
					<v-btn
						v-if="activeTab === 0"
						color="primary"
						class="rounded-medium"
						:disabled="!selectedImage"
						@click="$emit('image-selected', imageDueSize)"
					>
						{{$t('select')}}
					</v-btn>

					<template v-else-if="activeTab === 1">
						<!-- upload button -->
						<v-btn
							type="submit"
							color="primary"
							class="rounded-medium"
							:loading="uploadLoading"
							:disabled="removeLoading || uploadLoading"
							@click="isSubmitted = true"
						>
							{{$t('upload')}}
						</v-btn>

						<!-- delete button -->
						<v-btn
							v-if="isUploaded"
							color="red lighten-1 white--text"
							class="rounded-medium"
							:loading="removeLoading"
							:disabled="removeLoading"
							@click="remove()"
						>
							{{$t('delete')}}
						</v-btn>
					</template>

					<!-- cancel button -->
					<v-btn
						class="rounded-medium"
						:disabled="uploadLoading || removeLoading"
						@click="localeValue = false"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>

		<!-- preview dialog -->
		<v-dialog
			v-model="previewDialog"
			:max-width="imageSize < 3 ? 300 : 600"
		>
			<v-card>
				<v-card-text class="d-flex justify-center align-center text-center pa-3">
					<img :src="imageDueSize" class="fileManager__preview-image"/>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { BASE_URL } from '@/helpers/constants';
import { mapState } from 'vuex';
const imageSizes = {
	small: '_S',
	medium: '_M',
	large: '_L',
	original: '_CP',
}
export default {
	name: 'VFileManager',
	
	props: {
		value: { type: Boolean, default: false }
	},

	data() {
		return {
			// helpers
			activeTab: 0,
			imageSize: 2,
			
			loading: false,
			
			selectedImage: null,
			lastUploadedImage: null,
			
			previewDialog: false,
			
			uploadLoading: false,
			removeLoading: false,
			
			isSubmitted: false,
			isUploaded: false,

			// data
			uploadImage: null,
		}
	},

	watch: {
		localeValue(val) {
			if (val) {
				if (!this.images.length) {
					this.fetchImages();
				}
			} else {
				this.imageSize = 2;
				this.uploadImage = null;
				this.selectedImage = null;
				this.lastUploadedImage = null;
				this.$refs.form.reset();
				this.isSubmitted = false;
				this.isUploaded = false;
			}
		}
	},

	computed: {
		...mapState({
			images: state => state.fileManager.images
		}),

		localeValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		},

		// images tab
		imagesFilters() {
			return [
				{ id: 1, name: this.$t('components.filesFilters.small') },
				{ id: 2, name: this.$t('components.filesFilters.medium') },
				{ id: 3, name: this.$t('components.filesFilters.large') },
				{ id: 4, name: this.$t('components.filesFilters.original') },
			]
		},

		imageDueSize() {
			if (this.selectedImage) {
				const splittedUrl = this.selectedImage.split('.');
				const imagePath = splittedUrl[splittedUrl.length - 2].split('_')[0].split('/').slice(1).join('/');
				const imageExtension = splittedUrl[splittedUrl.length - 1];

				let size;
				switch (this.imageSize) {
				case 1: size = imageSizes.small; break; 
				case 2: size = imageSizes.medium; break;
				case 3: size = imageSizes.large; break;
				case 4: size = imageSizes.original; break;
				}
				return BASE_URL + '/' + imagePath + size + '.' + imageExtension;
			} else {
				return null
			}
		},

		imageSizeOnUse() {
			const imageObj = this.images.find(c => c.url === this.imageDueSize);
			if (imageObj) {
				const size = imageObj.size / 1024;
				if (size > 1024) {
					return (size / 1024).toFixed(3) + ' MB' 
				}
				return size.toFixed(2) + ' KB'
			} else {
				return '-'
			}
		},

		mediumImages() {
			return this.images.map(c => c.url).filter(c => c.includes(imageSizes.medium));
		}
	},

	methods: {
		fetchImages() {
			this.loading = true;
			this.$store.dispatch('fileManager/fetchImages')
				.finally(() => {
					this.loading = false;
				});
		},

		upload() {
			if (this.$refs.form.validate() && this.uploadImage) {
				this.uploadLoading = true;
				this.$store.dispatch('fileManager/uploadImage', { image: this.uploadImage })
					.then((data) => {
						this.$eventBus.$emit('show-snackbar', false, this.$t('messages.image-uploaded'));
						this.fetchImages();
						this.$refs.form.reset();
						// this.uploadImage = null;
						this.lastUploadedImage = data.data.path;
						this.isSubmitted = false;
						this.isUploaded = true;
						// this.activeTab = 0;
						// this.imageSize = 2
					})
					.finally(() => {
						this.uploadLoading = false;
					});
			}
		},

		remove() {
			this.removeLoading = true;
			this.$store.dispatch('fileManager/deleteImage', { imageUrl: this.lastUploadedImage })
				.then(() => {
					this.fetchImages();
					this.$eventBus.$emit('show-snackbar', true, this.$t('messages.deleted-successfully'));
					this.uploadImage = null;
					this.lastUploadedImage = null;
					this.isUploaded = null;
				})
				.finally(() => {
					this.removeLoading = false;
				})
		}
	},
}
</script>

<style lang="scss">
.fileManager {
	&__image {
		cursor: pointer;

		&--selected {
			outline: 2px solid #083966ce;
			outline-offset: 2px;
		}
	}
	&__preview-image {
		max-width: 100%;
	}
}
</style>