<template>
	<v-form ref="form" @submit.prevent="submit">
		<!-- home page -->
		<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.home-page')}}</div>
		<v-row>
			<!-- SEO keywords -->
			<v-col lg="6" cols="12">
				<v-text-field
					v-model="homePage.keywords"
					:label="$t('inputs.seo-keywords')"
					outlined
					dense
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					hint="keywords should separated by (,) eg: keyword1, keyword2"
					@input="homePage.keywords = homePage.keywords.replace(/(\w) /g, '$1, ')"
				></v-text-field>
			</v-col>

			<!-- SEO description -->
			<v-col lg="6" cols="12">
				<v-textarea
					v-model="homePage.description"
					:label="$t('inputs.seo-description')"
					outlined
					dense
					auto-grow
					rows="1"
					no-resize
					counter
					:hint="homePage.description && homePage.description.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : null"
					persistent-hint
					:color="homePage.description && homePage.description.length > 155 ? 'warning' : null"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					@keypress.enter.prevent
					@change="homePage.description = homePage.description.replace(/\n/g, ' ')"
				></v-textarea>
			</v-col>
		</v-row>

		<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('SEOpage.news-page')}}</div>
		<v-row>
			<!-- SEO keywords -->
			<v-col lg="6" cols="12">
				<v-text-field
					v-model="news.keywords"
					:label="$t('inputs.seo-keywords')"
					outlined
					dense
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					hint="keywords should separated by (,) eg: keyword1, keyword2"
					@input="news.keywords = news.keywords.replace(/(\w) /g, '$1, ')"
				></v-text-field>
			</v-col>

			<!-- SEO description -->
			<v-col lg="6" cols="12">
				<v-textarea
					v-model="news.description"
					:label="$t('inputs.seo-description')"
					outlined
					dense
					auto-grow
					rows="1"
					no-resize
					counter
					:hint="news.description && news.description.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : null"
					persistent-hint
					:color="news.description && news.description.length > 155 ? 'warning' : null"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					@keypress.enter.prevent
					@change="news.description = news.description.replace(/\n/g, ' ')"
				></v-textarea>
			</v-col>
		</v-row>

		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
	</v-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'SEOpage',
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,

		// data
		homePage: {
			description: null,
			keywords: null,
		},
		news: {
			description: null,
			keywords: null,
		},
	}),

	computed: {
		...mapState({
			SEO: state => state.SEO.SEO
		})
	},

	methods: {
		submit() {
			this.submitLoading = true;
			this.$store.dispatch('SEO/create', { 
				homeDescription: this.homePage.description,
				homeKeywords: this.homePage.keywords,
				newsDescription: this.news.description,
				newsKeywords: this.news.keywords
			})
				.then(() => {
					const message = this.$t('$-is-updated-successfully', { name: this.$t('SEOpage.seo-fields') })
					this.$eventBus.$emit('show-snackbar', false, message);
					this.$store.dispatch('SEO/fetchAll');
				})
				.finally(() => {
					this.submitLoading = false;
				})
		},

		fillToEdit() {
			this.homePage.description = this.SEO.find(c => c.key === 'home_description')?.value;
			this.homePage.keywords = this.SEO.find(c => c.key === 'home_keyword')?.value;
			this.news.description = this.SEO.find(c => c.key === 'news_description')?.value;
			this.news.keywords = this.SEO.find(c => c.Key === 'news_keywords')?.value;
		}
	},

	created() {
		if (Object.keys(this.SEO).length === 0) {
			this.loading = true;
			this.$store.dispatch('SEO/fetchAll')
				.then(() => {
					this.fillToEdit();
				})
				.finally(() => {
					this.loading = false;
				})
		} else {
			this.fillToEdit();
		}
	}
}
</script>

<style>

</style>