export const preview = false;
export const login = true;
export const dashboard = true;
export const sets = false;
export const categories = true;
export const category = true;
export const filters = false;
export const keywords = false;
export const products = true;
export const product = true;
export const news = true;
export const newsIndex = true;
export const services = false;
export const service = false;
export const orders = false;
export const order = false;
export const albums = false;
export const videos = false;
export const sliders = true;
export const users = false;
export const branches = true;
export const branch = true;
export const subscriptions = false;
export const aboutUs = true;
export const companyValues = false;
export const companyValue = false;
export const companyHistories = false;
export const companyHistory = false;
export const SEO = false;
