<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="products"
			:ids="deleteItemId"
			@on-delete="fetchProducts"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Products.the-product')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="products"
			:ids="restoreItemId"
			@on-restore="fetchProducts"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Products.the-product')}}
		</bee-restore-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('Products.confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('Products.are-you-sure-you-want-to-$-this-user', { state: titleState })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeUserState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- order dialog -->
		<v-dialog
			v-model="orderDialog"
			width="400"
			:persistent="changeOrderLoading"
		>
			<v-form ref="orderFrom" @submit.prevent="changeOrder(false, orderItem)">
				<v-card class="rounded-medium">
					<v-card-title class="justify-center text-uppercase text-h6">
						{{ $t('changing-order') }}
					</v-card-title>
					<v-card-text class="text-body-1 pb-2">
						<v-text-field
							v-model.number="orderItem"
							:label="$t('inputs.order')"
							dense
							outlined
							hide-details
							class="rounded-medium"
							:rules="rules.requiredAsNumber"
						></v-text-field>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="primary"
							class="rounded-small"
							:loading="changeOrderLoading"
							:disabled="changeOrderLoading"
							type="submit"
						>
							{{$t('change')}}
						</v-btn>
						<v-btn
							class="rounded-small"
							:disabled="changeOrderLoading"
							@click="orderDialog = false;"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							:to="{ name: 'product' }"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Products.add-new-product')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredProducts"
			:loading="loading || orderLoading"
			:items-per-page="15"
			:item-class="item => dragIndex === products.indexOf(item) ? 'drag-item' : null"
			class="elevation-3 rounded-medium"
		>
			<!-- order -->
			<template v-slot:item.num="{ item }">
				{{item.num}}
			</template>
			
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primaryImage.url"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- categories -->
			<template v-slot:item.categories="{ item }">
				{{item.categoriesIds.map(id => getCategoryById(id).name).join(', ') | isAvailable | ellipsis}}
			</template>

			<!-- date -->
			<template v-slot:item.dateOfPublication="{ item }">
				{{item.dateOfPublication ? moment(item.dateOfPublication).format('DD-MM-YYYY') : '-' }}
			</template>

			<!-- summary -->
			<template v-slot:item.summary="{ item }">
				{{item.summary | isAvailable | ellipsis}}
			</template>
			
			<!-- isFeatured -->
			<template v-slot:item.isFeatured="{ item }">
				<v-icon v-if="item.isFeatured">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- in home -->
			<template v-slot:item.inHomePage="{ item }">
				<v-icon v-if="item.inHomePage">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item, index }">
				<div
					class="d-flex justify-center align-center"
					@dragover.prevent
					@dragend="dragIndex = null;"
					@dragenter="dragIndex = index"
					@drop="
						orderItemId !== item.id
						? (() => {
							getDataOnDrop($event, index, products);
							changeOrder(true, index + 1);
						})()
						: null;
					"
				>
					<template v-if="!item.deletedAt">
						<v-btn
							v-if="showPreview && previewUrl"
							color="deep-purple darken-2 white--text"
							small
							class="px-1 flex-shrink-1"
							min-width="20px"
							:to="{ name: 'preview', query: { url: `${previewUrl}/${item.slug}` } }"
						><v-icon>mdi-eye</v-icon></v-btn>
						<v-btn
							color="blue darken-4"
							dark
							small
							class="ms-1 px-1 flex-shrink-1"
							min-width="20px"
							:to="{ name: 'product', params: { slug: item.slug, id: item.id } }"
						><v-icon>mdi-pencil</v-icon></v-btn>
						<v-btn
							v-if="useOrderingItems"
							color="teal darken-1"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="orderItemId = item.id; orderItem = item.num; orderDialog = true;"
						>
							<v-icon>mdi-swap-vertical</v-icon>
						</v-btn>
						<v-btn
							color="red darken-1"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="deleteItemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
						<v-switch
							v-model="item.isActive"
							:ripple="false"
							color="darkGreen"
							hide-details
							class="ms-2 mt-0 pt-0"
							readonly
							@click="activeConfirmDialog(item)"
						></v-switch>

						<!-- drag Icon -->
						<div
							v-if="useOrderingItems"
							class="drag-dots"
							:draggable="!orderLoading"
							@dragstart="
								orderItemId = item.id;
								setDataOnDrag($event, index);
							"
						>
							<v-icon>mdi-drag-vertical</v-icon>
						</div>
					</template>
					<v-btn
						v-else
						color="orange darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="restoreItemId = item.id; restoreDialog = true"
					>
						<v-icon>mdi-restore</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
import { products } from '@/configs/translates.config'
import { product as productPreview } from '@/configs/preview.config'
import { orders, preview } from '@/configs/routes.config';
import { useInHomePage, useOrderingItems } from '@/configs/global.config';
import { getDataOnDrop, setDataOnDrag } from '@/helpers/functions';
import rules from '@/helpers/validation rules';

export default {
	name: 'Products',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		confirmDialog: false,
		changeStateLoading: false,
		confirmId: null,

		orderDialog: false,
		changeOrderLoading: false,
		orderLoading: false,
		orderItemId: null,
		orderItem: null,

		// configs
		showPrice: orders.show,
		showPreview: preview.show,
		useInHomePage,
		useOrderingItems,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		titleState: null,
		loading: false,
		previewUrl: productPreview,
		dragIndex: null,

		rules,
	}),

	watch: {
		orderDialog(val) {
			if (!val) {
				this.$refs.orderFrom.reset();
				this.orderItemId = null;
			}
		}
	},

	computed: {
		...mapState({
			products: state => state.products.products,
			categories: state => state.categories.categories,
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById'
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.category'), value: 'categories', align: 'center', sortable: false },
				{ text: this.$t('headers.date'), value: 'dateOfPublication', align: 'center', sortable: false },
				{ text: this.$t('headers.price'), value: 'price', align: 'center' },
				{ text: this.$t('headers.summary'), value: 'summary', align: 'center', width: 200 },
				{ text: this.$t('headers.featured'), value: 'isFeatured', align: 'center' },
				{ text: this.$t('headers.in-home'), value: 'inHomePage', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];

			if (!this.showPrice) {
				headers.splice(4, 1);
			}
			if (useOrderingItems) {
				for (let i = 0; i < headers.length; i++) {
					headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
					headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
				}

				headers.splice(0, 0, { text: this.$t('headers.order'), value: 'num', align: 'center', width: 80, class: 'ps-3 px-1', cellClass: 'px-2' });
			}

			if (!useInHomePage) {
				headers.splice(headers.length - 2, 1)
			}

			return headers
		},

		filteredProducts() {
			return this.filterByName(this.isFiltered, this.products, this.search)
		},
	},

	methods: {
		fetchProducts(isFirstFetch) {
			this.loading = true;
			Promise.all([
				isFirstFetch && this.categories.length === 0 ? this.$store.dispatch('categories/fetchAll') : null,
				this.$store.dispatch('products/fetchAll', {
					useOrder: useOrderingItems
				})
			]).finally(() => {
				this.loading = false;
			})
		},

		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.isActive ? this.$t('Products.deactivate') : this.$t('Products.activate');
			this.confirmDialog = true;
		},

		changeUserState() {
			this.changeStateLoading = true;
			this.$store.dispatch('products/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('$-is-updated-successfully', { name: products.en.singular }));
				this.fetchProducts()
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		changeOrder(useOrderLoading, order) {
			if (useOrderLoading) {
				this.orderLoading = true;
			} else {
				this.changeOrderLoading = true;
			}
			this.$store.dispatch('products/changeOrder', {
				id: this.orderItemId,
				order,
			}).then(() => {
				this.orderDialog = false;
				this.$eventBus.$emit('show-snackbar', false, this.$t('messages.item-order-is-updated'));
				if (useOrderLoading) {
					this.$store.dispatch('products/fetchAll', { useOrder: useOrderingItems });
				} else {
					this.fetchProducts();
				}
			}).finally(() => {
				this.orderLoading = false;
				this.changeOrderLoading = false;
			})
		},

		// helpers
		moment,
		setDataOnDrag,
		getDataOnDrop,
	},

	created() {
		this.fetchProducts(true);
	}
};
</script>

<style>
</style>
