<template>
	<v-navigation-drawer
		id="navigation-drawer"
		v-model="localeValue"
		app
		dark
		:mobile-breakpoint="$vuetify.breakpoint.thresholds.md"
		:right="$i18n.locale === 'ar' || $vuetify.rtl"
		color="primary"
		:width="showCompanyValues || showCompanyHistory ? 215 : 200"
		:permanent="$vuetify.breakpoint.mdAndUp"
		:mini-variant="$vuetify.breakpoint.mdAndUp && !localeValue"
		mini-variant-width="55"
	>
		<div class="d-flex flex-column justify-space-between" style="height: 100%">
			<div>
				<!-- brand image -->
				<router-link
					:to="{ name: 'dashboard' }"
					class="d-flex justify-center align-center mt-3"
				>
					<v-img
						src="../assets/logo.png"
						max-width="110"
						width="40"
						min-width="20"
						:class="{'mx-1': localeValue }"
					/>
				</router-link>
				<v-list
					nav
					:dense="pages.filter(page => page.show).length > Math.floor(($vuetify.breakpoint.height - (110 + 37)) / 56)"
				>
					<v-list-item-group>
						<v-list-item
							v-for="(page, index) in pages.filter(page => page.show)"
							:key="index"
							:to="{ name: page.target }"
							:disabled="page.disabled"
							exact-path
						>
							<v-list-item-icon>
								<v-icon>{{page.icon}}</v-icon>
							</v-list-item-icon>
							<v-list-item-title class="text-capitalize">
								{{page.title}}
							</v-list-item-title>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</div>
			<div class="powered-by px-2 pb-1">
				<p class="mb-0">
					Midad 
					<span v-if="!localeValue || $vuetify.breakpoint.smAndDown">CMS</span>
					<span v-else>v{{version}}</span>
				</p>
				<p v-if="localeValue || $vuetify.breakpoint.smAndDown" class="mb-0">
					powered by
					<a target="_blank" href="https://www.beetronix.com">Beetronix</a>
				</p>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import * as routesConfigs from '@/configs/routes.config'
import { VERSION } from '@/helpers/constants';
export default {
	name: 'NavigationDrawer',

	props: {
		value: { type: Boolean, default: false }
	},

	data: () => ({
		version: VERSION,

		showCompanyValues: routesConfigs.companyValues.show,
		showCompanyHistory: routesConfigs.companyHistories.show,
	}),

	computed: {
		pages() {
			return [
				{
					title: this.$t('NavigationDrawer.pages.dashboard'),
					target: routesConfigs.dashboard.name,
					icon: 'mdi-view-dashboard',
					disabled: false,
					show: routesConfigs.dashboard.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.sets'),
					target: routesConfigs.sets.name,
					icon: 'mdi-list-box-outline',
					disabled: false,
					show: routesConfigs.sets.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.categories'),
					target: routesConfigs.categories.name,
					icon: 'mdi-format-list-bulleted-square',
					disabled: false,
					show: routesConfigs.categories.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.filters'),
					target: routesConfigs.filters.name,
					icon: 'mdi-filter-multiple',
					disabled: false,
					show: routesConfigs.filters.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.keywords'),
					target: routesConfigs.keywords.name,
					icon: 'mdi-tag-multiple',
					disabled: false,
					show: routesConfigs.keywords.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.products'),
					target: routesConfigs.products.name,
					icon: 'mdi-package-variant',
					disabled: false,
					show: routesConfigs.products.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.news'),
					target: routesConfigs.news.name,
					icon: 'mdi-newspaper',
					disabled: false,
					show: routesConfigs.news.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.services'),
					target: routesConfigs.services.name,
					icon: 'mdi-hexagon-multiple-outline',
					disabled: false,
					show: routesConfigs.services.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.orders'),
					target: 'orders',
					icon: 'mdi-file-document-outline',
					disabled: false,
					show: routesConfigs.orders.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.albums'),
					target: routesConfigs.albums.name,
					icon: 'mdi-image-album',
					disabled: false,
					show: routesConfigs.albums.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.videos'),
					target: routesConfigs.videos.name,
					icon: 'mdi-movie-open-play',
					disabled: false,
					show: routesConfigs.videos.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.sliders'),
					target: routesConfigs.sliders.name,
					icon: 'mdi-palette-swatch',
					disabled: false,
					show: routesConfigs.sliders.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.users'),
					target: routesConfigs.users.name,
					icon: 'mdi-account-group',
					disabled: false,
					show: routesConfigs.users.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.branches'),
					target: routesConfigs.branches.name,
					icon: 'mdi-map-marker-radius',
					disabled: false,
					show: routesConfigs.branches.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.subscriptions'),
					target: routesConfigs.subscriptions.name,
					icon: 'mdi-email',
					disabled: false,
					show: routesConfigs.subscriptions.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.about-us'),
					target: routesConfigs.aboutUs.name,
					icon: 'mdi-information-outline',
					disabled: false,
					show: routesConfigs.aboutUs.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.company-values'),
					target: routesConfigs.companyValues.name,
					icon: 'mdi-script-text',
					disabled: false,
					show: routesConfigs.companyValues.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.company-histories'),
					target: routesConfigs.companyHistories.name,
					icon: 'mdi-timeline-clock-outline',
					disabled: false,
					show: routesConfigs.companyHistories.show,
				},
				{
					title: this.$t('NavigationDrawer.pages.seo'),
					target: routesConfigs.SEO.name,
					icon: 'mdi-robot-happy-outline',
					disabled: false,
					show: routesConfigs.SEO.show,
				},
			]
		},

		localeValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		}
	}
}
</script>

<style lang="scss">
#navigation-drawer {
	*::-webkit-scrollbar {
		width: 5px;
	}
}
</style>