import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		filters: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/filters').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.filters ? !c.deletedAt : true);
				commit(types.STORE_FILTERS, storeData);
				return storeData;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/filters/${slug}`, configs).then(({ data }) => data);
		},
		create({ commit }, { name, image, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, image }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			return axios.post('/filters', formData, configs).then(({ data }) => data);
		},
		update({ commit }, { id, name, image, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { name, image }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})
			formData.append('_method', 'PUT');

			return axios.post(`/filters/${id}`, formData, configs).then(({ data }) => data);
		},
		delete({ commit }, { id }) {
			return axios.delete(`/filters/${id}`)
		},
		restore({ commit }, { id }) {
			return axios.patch(`/filters/${id}`)
		},
	},

	mutations: {
		[types.STORE_FILTERS](state, filters) {
			state.filters = filters;
		}
	},

	getters: {
		getFilterById: state => id => state.filters.find(c => c.id === id),
		getFilterBySlug: state => slug => state.filters.find(c => c.slug === slug),
	}
}