<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="filters"
			:ids="deleteItemId"
			@on-delete="fetchFilters"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Filters.the-filter')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="filters"
			:ids="restoreItemId"
			@on-restore="fetchFilters"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Filters.the-filter')}}
		</bee-restore-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="400"
			:persistent="dialogLoading"
		>
			<v-form ref="form" @submit.prevent="submit">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Filters.filter')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:disable-non-selected="isInputsChanged || dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFields"
						/>

						<!-- inputs -->
						<v-row class="flex-column mt-0">
							<v-col class="py-0">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
									@change="isInputsChanged = true"
								></v-text-field>
							</v-col>

							<!-- image -->
							<v-col v-if="useUploadFilterImage" class="pt-0">
								<bee-drop-img
									v-model="image"
									dashed
									border-width="2"
									:border-color="isSubmitted & !image ? '#f00' : '#ccc'"
									radius="11"
									height="180px"
									blurable
									clearable
									:disabled="dialogLoading"
									@input="isInputsChanged = true"
								>
									<template v-slot:drag-text>
										<span class="text-body-1 font-weight-medium">
											{{$t('drag-text')}}
										</span>
										<span class="text-caption mb-0">
											{{$t('supported-text')}}
										</span>
									</template>
								</bee-drop-img>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submiy"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Filters.add-new-filter')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredFilters"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.image ? item.image.url : require('../../assets/placeholder-image.png')"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<template v-if="!item.deletedAt">
					<v-btn
						color="blue darken-4"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="editItemId = item.id; dialog = true;"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteDialog = true; deleteItemId = item.id"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
				<v-btn
					v-else
					color="orange darken-1"
					dark
					small
					class="px-1"
					min-width="20px"
					@click="restoreItemId = item.id; restoreDialog = true"
				>
					<v-icon>mdi-restore</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { defaultLang, multiLangInputs } from '@/configs/translates.config';
import { useUploadFilterImage } from '@/configs/global.config';
export default {
	name: 'Filters',

	data: () => ({
		dialog: false,
		deleteDialog: false,

		editItemId: null,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		// filters
		search: null,
		isFiltered: null,

		// configs
		multiLangInputs,
		useUploadFilterImage,

		// helpers
		loading: false,
		dialogLoading: false,
		isSubmitted: false,
		lang: defaultLang,
		isInputsChanged: false,
		rules,

		// data
		name: null,
		image: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchFilter();
					} else {
						const item = this.getFilterById(this.editItemId).name;
						this.name = item.name;
						this.image = item.image?.url ?? null;
					}
				}
			} else {
				this.$refs.form.reset();
				this.image = null;
				this.isSubmitted = false;
				this.editItemId = null;
				this.lang = defaultLang;
				this.isInputsChanged = false;
			}
		}
	},

	computed: {
		...mapState({
			filters: state => state.filters.filters	
		}),

		...mapGetters({
			getFilterById: 'filters/getFilterById'
		}),

		filteredFilters() {
			return this.filterByName(this.isFiltered, this.filters, this.search);
		},

		headers() {
			const headers = [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];

			if (useUploadFilterImage) {
				headers.splice(0, 0, { text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },)
			}

			return headers;
		}
	},

	methods: {
		submit() {
			this.isSubmitted = true;
			const image = useUploadFilterImage ? this.image : true;
			if (this.$refs.form.validate() && image) {
				const actionName = this.editItemId ? 'update' : 'create';
				this.dialogLoading = true;
				this.$store.dispatch(`filters/${actionName}`, {
					id: this.editItemId,
					name: this.name,
					image: typeof this.image !== 'string' ? this.image : null,
					acceptLanguage: this.lang
				}).then((data) => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Filters.filter') })
							: this.$t('$-is-created-successfully', { name: this.$t('Filters.filter') });
					this.$eventBus.$emit('show-snackbar', false, message);

					this.isInputsChanged = false
					this.fetchFilters();

					if (multiLangInputs) {
						this.editItemId = data.id;
					} else {
						this.dialog = false;
					}
				}).finally(() => {
					this.dialogLoading = false;
				})
			}
		},

		updateFields() {
			if (this.editItemId) {
				this.$refs.form.reset();
				this.fetchFilter();
			}
		},

		fetchFilters() {
			this.loading = true;
			this.$store.dispatch('filters/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		},

		fetchFilter() {
			this.dialogLoading = true;
			const slug = this.getFilterById(this.editItemId).slug;
			this.$store.dispatch('filters/fetchBySlug', { slug, acceptLanguage: this.lang })
				.then(data => {
					this.name = data.name;
					this.image = data.image?.url ?? null;
				})
				.finally(() => {
					this.dialogLoading = false;
				})
		}
	},

	created() {
		this.fetchFilters();
	}
}
</script>

<style>

</style>