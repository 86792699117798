import * as configs from '@/configs/routes.config'
import * as routesTitles from '@/configs/translates.config'

import Login from '@/pages/Login.vue'
import Preview from '@/pages/Preview.vue'
import Dashboard from '@/pages/Dashboard.vue'
import Categories from '@/pages/categories/Categories.vue'
import Sets from '@/pages/grouping-pages/Sets.vue'
import Category from '@/pages/categories/Category.vue'
import Filters from '@/pages/grouping-pages/Filters.vue'
import Keywords from '@/pages/grouping-pages/Keywords.vue'
import Products from '@/pages/products/Products.vue'
import Product from '@/pages/products/Product.vue'
import News from '@/pages/news/News.vue'
import NewsIndex from '@/pages/news/NewsIndex.vue'
import Services from '@/pages/services/Services.vue'
import Service from '@/pages/services/Service.vue'
import Albums from '@/pages/albums/Albums.vue'
import Videos from '@/pages/videos/Videos.vue'
import Orders from '@/pages/orders/Orders.vue'
import Order from '@/pages/orders/Order.vue'
import Users from '@/pages/users/Users.vue'
import Branches from '@/pages/branches/Branches.vue'
import Branch from '@/pages/branches/Branch.vue'
import Slider from '@/pages/Sliders.vue'
import Subscriptions from '@/pages/users/Subscriptions.vue'
import CompanyValues from '@/pages/company-values/CompanyValues.vue'
import CompanyValue from '@/pages/company-values/CompanyValue.vue'
import CompanyHistories from '@/pages/company-histories/CompanyHistories.vue'
import CompanyHistory from '@/pages/company-histories/CompanyHistory.vue'
import AboutUs from '@/pages/AboutUs.vue'
import SEOpage from '@/pages/seo/SEOpage.vue'

const routes = [
	/* dashboard */
	{
		path: `/${configs.dashboard.inPathName}`,
		name: configs.dashboard.name,
		component: Dashboard,
		title: routesTitles.dashboard,
		isAddEditPage: configs.dashboard.isAddEditPage
	},
	/* sets */
	{
		path: `/${configs.sets.inPathName}`,
		name: configs.sets.name,
		component: Sets,
		title: routesTitles.sets,
		isAddEditPage: configs.sets.isAddEditPage
	},
	/* categories */
	{
		path: `/${configs.categories.inPathName}`,
		name: configs.categories.name,
		component: Categories,
		title: routesTitles.categories,
		isAddEditPage: configs.categories.isAddEditPage
	},
	/* category */
	{
		path: `/${configs.category.inPathName}/:id?`,
		name: configs.category.name,
		component: Category,
		title: routesTitles.category,
		isAddEditPage: configs.category.isAddEditPage
	},
	/* filters */
	{
		path: `/${configs.filters.inPathName}`,
		name: configs.filters.name,
		component: Filters,
		title: routesTitles.filters,
		isAddEditPage: configs.filters.isAddEditPage
	},
	/* keywords */
	{
		path: `/${configs.keywords.inPathName}`,
		name: configs.keywords.name,
		component: Keywords,
		title: routesTitles.keywords,
		isAddEditPage: configs.keywords.isAddEditPage
	},
	/* products */
	{
		path: `/${configs.products.inPathName}`,
		name: configs.products.name,
		component: Products,
		title: routesTitles.products,
		isAddEditPage: configs.products.isAddEditPage
	},
	/* product */
	{
		path: `/${configs.product.inPathName}/:slug?/:id?`,
		name: configs.product.name,
		component: Product,
		title: routesTitles.product,
		isAddEditPage: configs.product.isAddEditPage
	},
	/* news */
	{
		path: `/${configs.news.inPathName}`,
		name: configs.news.name,
		component: News,
		title: routesTitles.news,
		isAddEditPage: configs.news.isAddEditPage
	},
	/* newsIndex */
	{
		path: `/${configs.newsIndex.inPathName}/:slug?/:id?`,
		name: configs.newsIndex.name,
		component: NewsIndex,
		title: routesTitles.newsIndex,
		isAddEditPage: configs.newsIndex.isAddEditPage
	},
	/* services */
	{
		path: `/${configs.services.inPathName}`,
		name: configs.services.name,
		component: Services,
		title: routesTitles.services,
		isAddEditPage: configs.services.isAddEditPage
	},
	/* service */
	{
		path: `/${configs.service.inPathName}/:slug?/:id?`,
		name: configs.service.name,
		component: Service,
		title: routesTitles.service,
		isAddEditPage: configs.service.isAddEditPage
	},
	/* orders */
	{
		path: `/${configs.orders.inPathName}`,
		name: configs.orders.name,
		component: Orders,
		title: routesTitles.orders,
		isAddEditPage: configs.orders.isAddEditPage
	},
	/* order */
	{
		path: `/${configs.order.inPathName}/:id`,
		name: configs.order.name,
		component: Order,
		title: routesTitles.order,
		isAddEditPage: configs.order.isAddEditPage
	},
	/* company-values */
	{
		path: `/${configs.companyValues.inPathName}`,
		name: configs.companyValues.name,
		component: CompanyValues,
		title: routesTitles.companyValues,
		isAddEditPage: configs.companyValues.isAddEditPage
	},
	/* company-value */
	{
		path: `/${configs.companyValue.inPathName}/:slug?/:id?`,
		name: configs.companyValue.name,
		component: CompanyValue,
		title: routesTitles.companyValue,
		isAddEditPage: configs.companyValue.isAddEditPage
	},
	/* company-histories */
	{
		path: `/${configs.companyHistories.inPathName}`,
		name: configs.companyHistories.name,
		component: CompanyHistories,
		title: routesTitles.companyHistories,
		isAddEditPage: configs.companyHistories.isAddEditPage
	},
	/* company-history */
	{
		path: `/${configs.companyHistory.inPathName}/:slug?/:id?`,
		name: configs.companyHistory.name,
		component: CompanyHistory,
		title: routesTitles.companyHistory,
		isAddEditPage: configs.companyHistory.isAddEditPage
	},
	/* albums */
	{
		path: `/${configs.albums.inPathName}`,
		name: configs.albums.name,
		component: Albums,
		title: routesTitles.albums,
		isAddEditPage: configs.albums.isAddEditPage
	},
	/* videos */
	{
		path: `/${configs.videos.inPathName}`,
		name: configs.videos.name,
		component: Videos,
		title: routesTitles.videos,
		isAddEditPage: configs.videos.isAddEditPage
	},
	/* sliders */
	{
		path: `/${configs.sliders.inPathName}`,
		name: configs.sliders.name,
		component: Slider,
		title: routesTitles.sliders,
		isAddEditPage: configs.sliders.isAddEditPage
	},
	/* users */
	{
		path: `/${configs.users.inPathName}`,
		name: configs.users.name,
		component: Users,
		title: routesTitles.users,
		isAddEditPage: configs.users.isAddEditPage
	},
	/* branches */
	{
		path: `/${configs.branches.inPathName}`,
		name: configs.branches.name,
		component: Branches,
		title: routesTitles.branches,
		isAddEditPage: configs.branches.isAddEditPage
	},
	/* service */
	{
		path: `/${configs.branch.inPathName}/:slug?/:id?`,
		name: configs.branch.name,
		component: Branch,
		title: routesTitles.branch,
		isAddEditPage: configs.branch.isAddEditPage
	},
	/* subscriptions */
	{
		path: `/${configs.subscriptions.inPathName}`,
		name: configs.subscriptions.name,
		component: Subscriptions,
		title: routesTitles.subscriptions,
		isAddEditPage: configs.subscriptions.isAddEditPage
	},
	/* about us */
	{
		path: `/${configs.aboutUs.inPathName}`,
		name: configs.aboutUs.name,
		component: AboutUs,
		title: routesTitles.aboutUs,
		isAddEditPage: configs.aboutUs.isAddEditPage
	},
	/* seo */
	{
		path: `/${configs.SEO.inPathName}`,
		name: configs.SEO.name,
		component: SEOpage,
		title: routesTitles.SEO,
		isAddEditPage: configs.SEO.isAddEditPage
	},
	/* preview */
	{
		path: `/${configs.preview.inPathName}`,
		name: configs.preview.name,
		component: Preview,
		title: routesTitles.preview,
	}
];

const availableRoute = [
	/* login */
	{
		path: `/${configs.login.inPathName}`,
		name: configs.login.name,
		component: Login,
		title: routesTitles.login,
		isAddEditPage: configs.login.isAddEditPage
	},
	
	{
		path: '*',
		redirect: '/'
	}
];

Object.keys(configs).filter(key => configs[key].show).forEach(key => {
	availableRoute.push(routes.find(route => route.name === configs[key].name));
})

export default availableRoute