<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="categories"
			:ids="deleteItemId"
			@on-delete="fetchCategories"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Categories.the-category')}}
		</bee-delete-dialog>
		
		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="categories"
			:ids="restoreItemId"
			@on-restore="fetchCategories"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text--darken-1">{{$t('restore')}}</span> 
			{{$t('Categories.the-category')}}
		</bee-restore-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="400"
			:persistent="dialogLoading"
		>
			<v-card class="rounded-medium">
				<v-form ref="form" @submit.prevent="submit">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Categories.category')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:disable-non-selected="isInputsChanged || dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFields"
						/>

						<!-- inputs -->
						<v-row class="flex-column mt-0">
							<!-- name -->
							<v-col class="py-0">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
									@change="isInputsChanged = true"
								></v-text-field>
							</v-col>

							<!-- set -->
							<v-col v-if="showSets && !useCategoryTree" class="py-0">
								<v-autocomplete
									v-model="setIds"
									:items="sets"
									item-text="name"
									item-value="id"
									:label="$t('inputs.set')"
									outlined
									dense
									multiple
									class="rounded-small"
									:rules="rules.requiredAsArray"
									@change="isInputsChanged = true"
								></v-autocomplete>
							</v-col>
							
							<template v-if="useCategoryTree">
								<!-- summery -->
								<v-col class="py-0">
									<v-text-field
										v-model="summery"
										:label="$t('inputs.summery')"
										outlined
										dense
										multiple
										class="rounded-small"
										@change="isInputsChanged = true"
									></v-text-field>
								</v-col>

								<!-- parent categories -->
								<v-col class="py-0">
									<v-autocomplete
										v-model="parentCategoryId"
										:items="categories"
										item-text="name"
										item-value="id"
										:label="$t('inputs.parent-category')"
										outlined
										dense
										class="rounded-small"
										:rules="rules.requiredAsNumber"
										@change="isInputsChanged = true"
									></v-autocomplete>
								</v-col>
							</template>
							
							<!-- image -->
							<v-col v-if="useUploadCategoryImage" class="pt-0">
								<bee-drop-img
									v-model="image"
									dashed
									border-width="2"
									:border-color="isSubmitted & !image ? '#f00' : '#ccc'"
									radius="11"
									height="180px"
									blurable
									clearable
									:disabled="dialogLoading"
									@input="isInputsChanged = true"
								>
									<template v-slot:drag-text>
										<span class="text-body-1 font-weight-medium">
											{{$t('drag-text')}}
										</span>
										<span class="text-caption mb-0">
											{{$t('supported-text')}}
										</span>
									</template>
								</bee-drop-img>
							</v-col>

							<!-- is-featured -->
							<v-col v-if="useFeatured.categories" class="py-0">
								<v-switch
									v-model="isFeatured"
									:label="$t('inputs.is-featured')"
									hide-details
									class="mt-0 ms-2 mb-1 pt-0"
									@change="isInputsChanged = true"
								></v-switch>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="6" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Categories.add-new-category')}}
						</v-btn>
					</v-col>
					<v-spacer/>

					<!-- sort & search -->
					<v-col lg="4" md="6" cols="12" class="text-md-start text-center">
						<v-row class="align-center">
							<v-col sm cols="12">
								<v-text-field
									v-model="search"
									:label="$t('search')"
									hide-details
									outlined
									dense
									prepend-inner-icon="mdi-magnify"
									class="rounded-medium"
								></v-text-field>
							</v-col>
							<v-col sm="auto" cols="12">
								<v-btn
									:color="isFiltered ? null : 'primary'"
									class="rounded-small"
									@click="isFiltered =! isFiltered"
								>
									{{isFiltered ? $t('cancel') : $t('search')}}
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- tree -->
		<v-card
			v-if="useCategoryTree"
			:loading="loading"
			class="rounded-medium"
		>
			<v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="pt-2 pb-1">
				<v-row class="flex-md-row flex-column align-center ma-0 ms-sm-8 text-body-2 font-weight-bold">
					<!-- name -->
					<v-col lg="3" class="py-2">{{$t('headers.name')}}</v-col>

					<!-- image -->
					<v-col v-if="useUploadCategoryImage" lg="2" class="py-2">{{$t('headers.image')}}</v-col>

					<!-- summary -->
					<v-col lg="5" class="py-2">{{$t('headers.summary')}}</v-col>

					<!-- actions -->
					<v-col lg="2" class="text-end py-2 pe-7">{{$t('headers.actions')}}</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-treeview
					dense
					:items="hierarchyCategories"
					:load-children="null"
					transition
					activatable
					open-all
					expand-icon="mdi-chevron-down"
					active-class
				>
					<template v-slot:label="{ item }">
						<v-row class="flex-md-row flex-column align-center tree-row ma-0">
							<!-- name -->
							<v-col lg="3" class="py-2">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.name')}}:
								</span>

								<span class="text-body-1 font-weight-medium primary--text">
									{{item.name[$i18n.locale]}}
								</span>
							</v-col>

							<!-- image -->
							<v-col v-if="useUploadCategoryImage" lg="2" class="py-2">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.image')}}:
								</span>

								<span class="text-body-1 font-weight-medium">
									<v-img
										v-if="true"
										:src="item.image || require('@/assets/placeholder-image.png')"
										:height="45"
										:width="45"
										lazy-src="@/assets/placeholder-image.png"
										class="rounded-medium"
									/>
									<template v-else-if="0">-</template>
								</span>
							</v-col>

							<!-- summary -->
							<v-col lg="5" class="py-2">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.summary')}}:
								</span>

								<p
									class="text-subtitle-2 main--text font-weight-medium mb-0 tree-row__summary"
								>
									{{
										((item.summary && item.summary[$i18n.locale]) || '')
										| isAvailable | ellipsis(140)
									}}
								</p>
							</v-col>

							<!-- actions -->
							<v-col lg="2" class="text-md-end text-center py-2">
								<v-btn
									color="blue darken-4"
									dark
									small
									class="px-1 flex-shrink-1"
									min-width="20px"
									@click="editItemId = id; dialog = true;"
								><v-icon>mdi-pencil</v-icon></v-btn>
								<v-btn
									v-if="!item.deletedAt"
									color="red darken-1"
									dark
									small
									class="ms-1 px-1"
									min-width="20px"
									@click="deleteItemId = item.id; deleteDialog = true;"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
								<v-btn
									v-else
									color="orange darken-1"
									dark
									small
									class="ms-1 px-1"
									min-width="20px"
									@click="restoreItemId = item.id; restoreDialog = true;"
								>
									<v-icon>mdi-restore</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-divider/>
					</template>
				</v-treeview>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			v-else
			:headers="headers"
			:items="loading ? [] : filteredCategories"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.image ? item.image : require('../../assets/placeholder-image.png')"
					:height="45"
					:width="45"
					lazy-src="../../assets/placeholder-image.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- sets -->
			<template v-slot:item.setsIds="{ item }">
				{{getSets(item) | isAvailable}}
			</template>

			<!-- action -->
			<template v-slot:item.actions="{ item }">
				<template v-if="!item.deletedAt">
					<v-btn
						color="blue darken-4"
						dark
						small
						class="px-1 flex-shrink-1"
						min-width="20px"
						@click="dialog = true; editItemId = item.id"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						color="red darken-1"
						dark
						small
						class="ms-1 px-1"
						min-width="20px"
						@click="deleteDialog = true; deleteItemId = item.id"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
				<v-btn
					v-else
					color="orange darken-1"
					dark
					small
					class="px-1"
					min-width="20px"
					@click="restoreItemId = item.id; restoreDialog = true;"
				>
					<v-icon>mdi-restore</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { useCategoryTree, useFeatured, useUploadCategoryImage } from '@/configs/global.config'
import { sets } from '@/configs/routes.config';
import { defaultLang, multiLangInputs } from '@/configs/translates.config';
export default {
	name: 'Categories',

	data: () => ({
		dialog: false,
		editItemId: null,
		
		deleteDialog: false,
		deleteItemId: null,
		
		restoreItemId: null,
		restoreDialog: null,
		
		isSubmitted: false,

		// configs
		showSets: sets.show,
		useCategoryTree,
		useUploadCategoryImage,
		multiLangInputs,
		useFeatured,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		dialogLoading: false,
		isInputsChanged: false,
		lang: defaultLang,
		rules,

		items: [
			{
				id: '1',
				name: 'Applications',
				numberOfProducts: 1,
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				image: require('@/assets/logo.png'),
				children: [
					{ id: '2', name: 'Calendar : app', numberOfProducts: 2 },
					{ id: '3', name: 'Chrome : app', numberOfProducts: 3 },
					{ id: '4', name: 'Webstorm : app', numberOfProducts: 4 },
				],
			},
			{
				id: '5',
				name: 'Documents',
				numberOfProducts: 5,
				image: require('@/assets/logo.png'),
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				children: [
					{
						id: '6',
						name: 'vuetify',
						numberOfProducts: 6,
						children: [
							{
								id: '7',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '8', name: 'index : ts', numberOfProducts: 8 },
									{ id: '9', name: 'bootstrap : ts', numberOfProducts: 9 },
								],
							},
						],
					},
					{
						id: '10',
						name: 'material2',
						numberOfProducts: 21,
						image: require('@/assets/logo.png'),
						summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
						children: [
							{
								id: '11',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '12', name: 'v-btn : ts', numberOfProducts: 12 },
									{ id: '13', name: 'v-card : ts', numberOfProducts: 13 },
									{ id: '14', name: 'v-window : ts', numberOfProducts: 14 },
								],
							},
						],
					},
				],
			}
		],

		// data
		name: null,
		setIds: [],
		summery: null,
		parentCategoryId: null,
		image: null,
		isFeatured: null
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchCategory();
					} else {
						const item = this.getCategoryById(this.editItemId);
						this.name = item.name;
						this.setIds = item.setsIds;
						this.summery = item.summery;
						this.parentCategoryId = item.parentId;
						this.image = item.image;
						this.isFeatured = item.isFeatured;
					}
				}
			} else {
				this.$refs.form.reset();
				this.image = null;
				this.editItemId = null;
				this.lang = defaultLang;
				this.isInputsChanged = false;
				this.isSubmitted = false;
			}
		}
	},

	computed: {
		...mapState({
			categories: state => state.categories.categories,
			hierarchyCategories: state => state.categories.hierarchyCategories,
			sets: state => state.sets.sets,
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById',
			getSetById: 'sets/getSetById',
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];
			if (this.showSets) {
				headers.splice(1, 0, { text: this.$t('headers.sets'), value: 'setsIds', align: 'center' });
			}
			if (this.useUploadCategoryImage) {
				headers.splice(0, 0, { text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false });
			}
			return headers
		},

		filteredCategories() {
			return this.filterByName(this.isFiltered, this.categories, this.search);
		}
	},

	methods: {
		getSets(item) {
			if (item.setsIds && item.setsIds.length) {
				const names = [];
				item.setsIds.forEach(id => {
					const set = this.getSetById(id).name;
					names.push(set)
				});
				return names.join(', ');
			}
		},

		submit() {
			this.isSubmitted = true;
			const state = this.useUploadCategoryImage ? this.image : true;
			if (this.$refs.form.validate() && state) {
				const actionName = this.editItemId ? 'update' : 'create';
				this.dialogLoading = true;
				this.$store.dispatch(`categories/${actionName}`, {
					id: this.editItemId,
					name: this.name,
					setIds: this.setIds,
					categoryId: this.parentCategoryId,
					summary: this.summery,
					image: typeof this.image !== 'string' ? this.image : null,
					isFeatured: this.isFeatured,
					acceptLanguage: this.lang
				}).then((data) => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Categories.category') })
							: this.$t('$-is-created-successfully', { name: this.$t('Categories.category') });
					this.$eventBus.$emit('show-snackbar', false, message);

					this.isInputsChanged = false;
					this.fetchCategories();

					if (multiLangInputs) {
						this.editItemId = data.id;
						this.fetchCategory();
					} else {
						this.dialog = false;
					}
				}).finally(() => {
					this.isSubmitted = false;
					this.dialogLoading = false;
				})
			}
		},

		updateFields() {
			if (this.editItemId) {
				this.$refs.form.reset();
				this.image = null;
				this.fetchCategory();
			}
		},

		fetchCategories() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('categories/fetchAll'),
				useCategoryTree ? this.$store.dispatch('categories/fetchAllAsHierarchy') : null
			]).finally(() => {
				this.loading = false;
			})
		},

		fetchCategory() {
			this.dialogLoading = true;
			const slug = this.getCategoryById(this.editItemId).slug;
			this.$store.dispatch('categories/fetchBySlug', { slug, acceptLanguage: this.lang })
				.then(data => {
					this.name = data.name;
					this.setIds = data.setsIds;
					this.summery = data.summery;
					this.parentCategoryId = data.parentId;
					this.image = data.image;
					this.isFeatured = data.isFeatured;
				})
				.finally(() => {
					this.dialogLoading = false;
				});
		}
	},

	created() {
		this.fetchCategories();
		if (this.sets.length === 0) {
			this.$store.dispatch('sets/fetchAll')
		}
	}
};
</script>

<style lang="scss" scoped>
.tree-row {
	&:hover {
		background-color: #eee;
	}
	&__summary {
		white-space: pre-line;
	}
}
</style>