import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index'
import { dashboard, login } from '@/configs/routes.config'
import { $eventBus } from '@/plugins/midad'
import i18n from '@/i18n/i18n'
import moment from 'moment'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { y: 0, behavior: 'smooth' }
	},
	routes,
})
router.beforeEach((to, from, next) => {
	if (to.name !== login.name && !store.state.auth.authenticated) {
		next({ name: login.name, replace: true })
	} else if (to.name === login.name && store.state.auth.authenticated) {
		next({ name: dashboard.name, replace: true })
	} else {
		const remainingMinutes = 
			moment.duration(moment(JSON.parse(localStorage.getItem('expiry-date'))).diff(moment())).minutes();		
		if (remainingMinutes < 0) {
			store.dispatch('auth/logout');
			$eventBus.$emit('show-snackbar', false, i18n.t('messages.session-is-end'))
			next({ name: login.name, replace: true });
		} else {
			next()
		}
	}
})

export default router
