import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		branches: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/branches').then(({ data }) => {
				const storeData = data.filter(c => !useRestore.branches ? !c.deletedAt : true);
				commit(types.STORE_BRANCHES, storeData);
				return storeData;
			});
		},

		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/branches/${slug}`, configs).then(({ data }) => data);
		},

		create({ commit }, { name, openHours, countryCode, moneyCode, isMain, image, email, address, phone, mobile, whatsapp, facebook, telegram, telegramChannel, twitter, youtube, linkedIn, pinterest, instagram, location, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { name, openHours, countryCode, moneyCode, image, email, address, phone, mobile, whatsapp, facebook, telegram, telegramChannel, twitter, youtube, linkedIn, pinterest, instagram, location }

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			formData.append('isMain', isMain ? 1 : 0);
			formData.append('withContacts', true);

			return axios.post('/branches', formData, configs).then(({ data }) => data);
		},

		update({ commit }, { id, name, openHours, countryCode, moneyCode, isMain, image, email, address, phone, mobile, whatsapp, facebook, telegram, telegramChannel, twitter, youtube, linkedIn, pinterest, instagram, location, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { name, openHours, countryCode, moneyCode, image, email, address, phone, mobile, whatsapp, facebook, telegram, telegramChannel, twitter, youtube, linkedIn, pinterest, instagram, location }

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			formData.append('isMain', isMain ? 1 : 0);
			formData.append('withContacts', true);

			formData.append('_method', 'PUT');

			return axios.post(`/branches/${id}`, formData, configs).then(({ data }) => data);
		},
		
		uploadImages({ commit }, { id, images }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});

			return axios.post(`/branches/${id}/add-images`, formData);
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.delete(`/branches/${id}/images/${imageId}`);
		},

		delete({ commit }, { id }) {
			return axios.delete(`/branches/${id}`);
		},

		restore({ commit }, { id }) {
			return axios.patch(`/branches/${id}`);
		},
	},

	mutations: {
		[types.STORE_BRANCHES](state, branches) {
			state.branches = branches;
		}
	},

	getters: {
		getBranchById: state => id => state.branches.find(c => c.id === id),
	}
}