import { products } from '@/configs/translates.config'
export default {
	statistics: {
		visitors: 'visitors',
		users: 'users',
		'disabled-products': 'disabled products',
		revenue: 'revenue'
	},

	descriptions: {
		visitors: 'Visitors right now',
		today: 'Login today',
		'out-of-$-products': `Out of {number} ${products.en.plural}`,
		orders: 'Orders'
	},

	'products-views': `${products.en.plural} views`,

	'order-status': {
		title: 'Order status',
		approved: 'Approved',
		'in-preparation': 'In preparation',
		shipping: 'Shipping',
		delivered: 'Delivered',
		unpaid: 'Unpaid'
	},

	'resent-added-products': `Resent added ${products.en.plural}`,
	'popular-products': `Popular ${products.en.plural}`
}