<template>
	<div class="video-player" :style="'--plyr-color-main:' + color">
		<!-- data-poster="poster.jpg" -->
		<video
			controls
			playsinline
			ref="video"
		>
			<source :src="src" type="video/mp4"/>
		</video>
	</div>
</template>

<script>
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { mainColor } from '@/configs/brand.config';
export default {
	name: 'VideoPlayer',
	
	props: {
		src: { type: String },
		color: { type: String, default: mainColor }
	},

	data: () => ({
		player: null,
		options: {
			controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
			speed: { selected: 1, options: [0.5, 1, 1.5] }
		}
	}),

	watch: {
		src(newVal) {
			if (newVal) {
				this.$refs.video.load();
			}
		}
	},

	updated() {
		this.player = new Plyr(this.$refs.video, this.options);
	},

	mounted() {
		this.player = new Plyr(this.$refs.video, this.options);
	},

	beforeDestroy() {
		this.player.pip = false;
		this.player.stop();
	}
}
</script>

<style lang="scss">
.video-player .plyr {
	border-radius: 11px;
}
</style>