import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		companyValues: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/company-values').then(({ data }) => {
				commit(types.STORE_COMPANY_VALUES, data);
				return data;
			});
		},

		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/company-values/${slug}`, configs).then(({ data }) => data);
		},

		create({ commit }, { title, content, image, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { title, content, image }

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			return axios.post('/company-values', formData, configs).then(({ data }) => data);
		},

		update({ commit }, { id, title, content, image, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			
			const requestData = { title, content, image }

			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			formData.append('_method', 'PUT');

			return axios.post(`/company-values/${id}`, formData, configs).then(({ data }) => data);
		},
		
		uploadImages({ commit }, { id, images }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});

			return axios.post(`/company-values/${id}/add-images`, formData);
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.delete(`/company-values/${id}/images/${imageId}`);
		},

		delete({ commit }, { id }) {
			return axios.delete(`/company-values/${id}`);
		},
		
		restore({ commit }, { id }) {
			return axios.patch(`/company-values/${id}`);
		}
	},

	mutations: {
		[types.STORE_COMPANY_VALUES](state, companyValues) {
			state.companyValues = companyValues;
		}
	},

	getters: {
		getCompanyValueById: state => id => state.companyValues.find(c => c.id === id)
	}
}