import { multiLangInputs } from '@/configs/translates.config';

export function headerAcceptLanguage(acceptLanguage) {
	let configs;
	if (multiLangInputs) {
		configs = { headers: { 'accept-language': acceptLanguage } }
	}
	return configs;
}

export function setDataOnDrag(event, index) {
	event.dataTransfer.setData('oldIndex', index);
	const dragImage = new Image();
	event.dataTransfer.setDragImage(dragImage, 0, 0)
}

export function getDataOnDrop(event, newIndex, array) {
	const oldIndex = event.dataTransfer.getData('oldIndex');
	if (newIndex !== oldIndex) {
		const oldItem = array[oldIndex];
		array.splice(oldIndex, 1);
		array.splice(newIndex, 0, oldItem);
	}
}