<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="isInputsChanged || submitLoading"
			class="mb-2"
			@change="updateFields"
		/>

		<!-- inputs -->
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col
				:lg="useUploadBranchImage ? 9 : 12"
				:md="useUploadBranchImage ? 8 : 12"
				cols="12"
				order-md="0"
				order="1"
				class="pb-md-0"
			>
				<v-row class="mt-0 mb-2">
					<!-- name -->
					<v-col lg="6" cols="12">
						<v-text-field
							v-model="name"
							:label="$t('inputs.name')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- country-code -->
					<v-col lg="6" cols="12" v-if="branchInfo.countryCode">
						<v-text-field
							v-model="countryCode"
							:label="$t('inputs.country-code')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- money-code -->
					<v-col lg="6" cols="12" v-if="branchInfo.moneyCode">
						<v-text-field
							v-model="moneyCode"
							:label="$t('inputs.money-code')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- address -->
					<v-col lg="6" cols="12" v-if="branchInfo.address">
						<v-text-field
							v-model="address"
							:label="$t('inputs.address')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- opening hours -->
					<v-col lg="6" cols="12" v-if="branchInfo.openingHours">
						<v-text-field
							v-model="openHours"
							:label="$t('inputs.opening-hours')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- location -->
					<v-col lg="6" cols="12" v-if="branchInfo.location">
						<v-text-field
							v-model="location"
							:label="$t('inputs.location')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="location ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- is-main -->
					<v-col lg="6" cols="12" class="d-flex flex-sm-row flex-column align-sm-center">
						<v-switch
							v-model="isMain"
							:label="$t('inputs.is-main-branch')"
							hide-details
							outlined
							class="mt-sm-0 ms-4 pt-0"
							:disabled="loading || submitLoading"
							@change="isInputsChanged = true"
						></v-switch>
					</v-col>		
				</v-row>

				<div class="text-body-1 font-weight-bold text-capitalize mb-3">
					{{$t('Branches.contact-info')}}
				</div>

				<v-row>
					<!-- email -->
					<v-col lg="6" cols="12" v-if="contacts.email">
						<v-text-field
							v-model="email"
							:label="$t('inputs.email')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="email ? rules.email : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>
					
					<!-- phone -->
					<v-col lg="6" cols="12" v-if="contacts.phone">
						<v-text-field
							v-model="phone"
							:label="$t('inputs.phone')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>
					
					<!-- mobile -->
					<v-col lg="6" cols="12" v-if="contacts.mobile">
						<v-text-field
							v-model="mobile"
							:label="$t('inputs.mobile')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="mobile ? rules.mobile : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>
					
					<!-- whatsapp -->
					<v-col lg="6" cols="12" v-if="contacts.whatsapp">
						<v-text-field
							v-model="whatsapp"
							:label="$t('inputs.whatsapp')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="whatsapp ? rules.mobile : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- telegram -->
					<v-col lg="6" cols="12" v-if="contacts.telegram">
						<v-text-field
							v-model="telegram"
							:label="$t('inputs.telegram')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="telegram ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- telegram channel -->
					<v-col lg="6" cols="12" v-if="contacts.telegramChannel">
						<v-text-field
							v-model="telegramChannel"
							:label="$t('inputs.telegram-channel')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="telegramChannel ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>
					
					<!-- facebook -->
					<v-col lg="6" cols="12" v-if="contacts.facebook">
						<v-text-field
							v-model="facebook"
							:label="$t('inputs.facebook')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="facebook ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- instagram -->
					<v-col lg="6" cols="12" v-if="contacts.instagram">
						<v-text-field
							v-model="instagram"
							:label="$t('inputs.instagram')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="instagram ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- twitter -->
					<v-col lg="6" cols="12" v-if="contacts.twitter">
						<v-text-field
							v-model="twitter"
							:label="$t('inputs.twitter')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="twitter ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- youtube -->
					<v-col lg="6" cols="12" v-if="contacts.youtube">
						<v-text-field
							v-model="youtube"
							:label="$t('inputs.youtube')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="youtube ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- linkedIn -->
					<v-col lg="6" cols="12" v-if="contacts.linkedIn">
						<v-text-field
							v-model="linkedIn"
							:label="$t('inputs.linkedIn')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="linkedIn ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- pinterest -->
					<v-col lg="6" cols="12" v-if="contacts.pinterest">
						<v-text-field
							v-model="pinterest"
							:label="$t('inputs.pinterest')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="pinterest ? rules.url : []"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>
				</v-row>
			</v-col>

			<template v-if="useUploadBranchImage">
				<v-col cols="auto" class="d-md-block d-none px-1">
					<v-divider vertical/>
				</v-col>
				
				<!-- drop image -->
				<v-col md cols="12" class="pb-md-0">
					<div class="sticky">
						<div class="text-body-1 font-weight-bold mb-3">
							{{$t('upload-images')}}
						</div>
						<bee-drop-img
							v-model="mainImage"
							dashed
							border-width="2"
							:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
							radius="11"
							height="250px"
							blurable
							clearable
							:loading="loading"
							:disabled="loading || submitLoading"
							:hide-image-name="false"
							@input="isInputsChanged = true"
						>
							<template v-slot:drag-text>
								<span class="text-body-1 font-weight-medium">
									{{$t('drag-text')}}
								</span>
								<span class="text-caption mb-0">
									{{$t('supported-text')}}
								</span>
							</template>
						</bee-drop-img>
						
						<bee-drop-zone
							v-if="uploadMoreImages"
							v-model="images"
							dashed
							drop-zone-classes="pa-3"
							radius="11"
							border-width="2"
							max-height="250"
							:drop-image-props="{
								width: 70,
								height: 70
							}"
							:clear-icon="$route.params.id ? 'mdi-delete' : 'mdi-close'"
							:clear-color="$route.params.id ? 'red' : null"
							:gap-size="4"
							:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
							:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
							:drop-menu-left="null"
							show-add-btn
							show-drop-menu
							no-drop-replace
							class="mt-3"
							:loading="loading"
							:disabled="loading || submitLoading"
							@remove-image="removeImage"
							@input="isInputsChanged = true"
						>
							<template v-slot:drag-text>
								<p class="mb-0">
									<span class="d-block text-body-1 font-weight-bold">
										{{$t('upload-more-image')}}
									</span>
									<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
								</p>
							</template>
						</bee-drop-zone>
					</div>
				</v-col>
			</template>
		</v-row>
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: backRouteName }"
			:disabled="loading || submitLoading"
		>
			{{$t('back')}}
		</v-btn>
	</v-form>
</template>

<script>
import rules from '@/helpers/validation rules'
import { branches } from '@/configs/routes.config';
import { defaultLang, multiLangInputs } from '@/configs/translates.config';
import { branchInfo, contacts, useUploadBranchImage, useUploadMoreImages } from '@/configs/global.config';
export default {
	name: 'Branch',
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		isSubmitted: false,
		productImages: [],
		lang: defaultLang,
		isInputsChanged: false,
		backRouteName: branches.name,
		rules,

		// configs
		multiLangInputs,
		branchInfo,
		contacts,
		useUploadBranchImage,
		uploadMoreImages: useUploadMoreImages.branches,
		
		// data
		name: null,
		isMain: null,
		mainImage: null,
		images: [],

		openHours: null,
		countryCode: null,
		moneyCode: null,
		location: null,
		
		email: null,
		address: null,
		phone: null,
		mobile: null,
		whatsapp: null,
		facebook: null,
		telegram: null,
		telegramChannel: null,
		instagram: null,
		twitter: null,
		youtube: null,
		linkedIn: null,
		pinterest: null,
	}),

	watch: {
		$route(newVal) {
			if (newVal.params.id) {
				this.loading = true;
				this.fetchBranches().finally(() => {
					this.isInputsChanged = false;
					this.loading = false;
				});
			}
		}
	},

	methods: {
		submit() {
			this.isSubmitted = true;
			const image = useUploadBranchImage ? this.mainImage : true;
			if (this.$refs.form.validate() && image) {
				this.submitLoading = true;
				const actionName = this.$route.params.id ? 'update' : 'create';
				// more images
				const images = this.images.filter(c => typeof c !== 'string');

				this.$store.dispatch(`branches/${actionName}`, {
					id: this.$route.params.id,
					name: this.name,
					openHours: this.openHours,
					countryCode: this.countryCode,
					moneyCode: this.moneyCode,
					isMain: this.isMain,
					image: typeof this.mainImage !== 'string' ? this.mainImage : null,
					email: this.email,
					address: this.address,
					phone: this.phone,
					mobile: this.mobile,
					whatsapp: this.whatsapp,
					facebook: this.facebook,
					telegram: this.telegram,
					telegramChannel: this.telegramChannel,
					twitter: this.twitter,
					youtube: this.twitter,
					linkedIn: this.linkedIn,
					pinterest: this.pinterest,
					instagram: this.instagram,
					location: this.location,
					acceptLanguage: this.lang
				}).then((data) => {
					// next route
					const nextRoute = () => {
						const message =
							actionName === 'update'
								? this.$t('$-is-updated-successfully', { name: this.$t('Branches.branch') })
								: this.$t('$-is-created-successfully', { name: this.$t('Branches.branch') });
						this.$eventBus.$emit('show-snackbar', false, message);

						if (multiLangInputs) {
							this.isInputsChanged = false;
							if (!this.$route.params.id) {
								this.$router.replace({
									name: this.$route.name,
									params: { slug: data.slug, id: data.id }
								});
							}
						} else {
							this.$router.push({ name: branches.name });
						}
					}

					// upload the other images
					if (images.length) {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-the-images'))
						this.$store.dispatch('branches/uploadImages', {
							id: data.id,
							images,
						}).then(() => {
							nextRoute();
						}).finally(() => {
							this.submitLoading = false;
						})
					} else {
						nextRoute();
					}
				}).finally(() => {
					if (!images.length) {
						this.submitLoading = false;
					}
				})
			}
		},

		// FIXME show dialog to confirm remove
		removeImage(image) {
			if (this.$route.params.id) {
				const imageId = this.productImages.find(c => c.url === image)?.id;
				if (imageId) {
					this.$store.dispatch('branches/deleteImage', {
						id: this.$route.params.id,
						imageId: imageId
					})
				}
			}
		},

		updateFields() {
			if (this.$route.params.id) {
				this.$refs.form.reset();
				this.loading = true;
				this.fetchBranches().finally(() => {
					this.isInputsChanged = false;
					this.loading = false
				});
			}
		},

		fetchBranches() {
			return this.$store.dispatch('branches/fetchBySlug', {
				slug: this.$route.params.slug,
				acceptLanguage: this.lang
			}).then((data) => {
				this.name = data.name;
				this.isMain = data.isMain;
				this.mainImage = data.primaryImage.url;
				this.images = data.images.map(c => c.url).filter(c => c !== data.primaryImage.url);
				
				this.openHours = data.openHours;
				this.countryCode = data.countryCode;
				this.moneyCode = data.moneyCode;

				this.email = data.contacts.email;
				this.address = data.contacts.address;
				this.phone = data.contacts.phone;
				this.mobile = data.contacts.mobile;
				this.whatsapp = data.contacts.whatsapp;
				this.facebook = data.contacts.facebook;
				this.telegram = data.contacts.telegram;
				this.telegramChannel = data.contacts.telegramChannel;
				this.instagram = data.contacts.instagram;
				this.twitter = data.contacts.twitter;
				this.youtube = data.contacts.youtube;
				this.linkedIn = data.contacts.linkedIn;
				this.pinterest = data.contacts.pinterest;
				this.location = data.contacts.location;

				this.productImages = data.images.filter(c => c !== data.primaryImage.url);
			})
		}
	},

	created() {
		if (this.$route.params.id) {
			this.loading = true;
			this.fetchBranches().finally(() => {
				this.loading = false;
			})
		}
	}
};
</script>

<style lang="scss">
</style>