import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		users: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/users').then(({ data }) => {
				commit(types.STORE_USERS, data);
				return data;
			})
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/users/view/${id}`).then(({ data }) => data)
		},
		changeState({ commit }, { id }) {
			return axios.put(`/users/changestatus/${id}`);
		},
	},

	mutations: {
		[types.STORE_USERS](state, users) {
			state.users = users;
		}
	},

	getters: {
		getUserById: state => id => state.users.find(c => c.id === id)
	}
}