import * as show from './generated/pages'

export const preview = {
	inPathName: 'preview',
	name: 'preview',
	show: show.preview
}

export const login = {
	inPathName: 'login',
	name: 'login',
}

export const dashboard = {
	inPathName: '',
	name: 'dashboard',
	show: show.dashboard
}

export const sets = {
	inPathName: 'sets',
	name: 'sets',
	show: show.sets
}

export const categories = {
	inPathName: 'categories',
	name: 'categories',
	show: show.categories
}

export const category = {
	inPathName: 'category',
	name: 'category',
	show: show.category
}

export const filters = {
	inPathName: 'filters',
	name: 'filters',
	show: show.filters
}

export const keywords = {
	inPathName: 'keywords',
	name: 'keywords',
	show: show.keywords
}

export const products = {
	inPathName: 'products',
	name: 'products',
	show: show.products
}

export const product = {
	inPathName: 'product',
	name: 'product',
	show: show.product,
	isAddEditPage: true
}

export const news = {
	inPathName: 'news',
	name: 'news',
	show: show.news
}

export const newsIndex = {
	inPathName: 'news-index',
	name: 'news-index',
	show: show.newsIndex,
	isAddEditPage: true
}

export const services = {
	inPathName: 'services',
	name: 'services',
	show: show.services
}

export const service = {
	inPathName: 'service',
	name: 'service',
	show: show.service,
	isAddEditPage: true
}

export const orders = {
	inPathName: 'orders',
	name: 'orders',
	show: show.orders
}

export const order = {
	inPathName: 'order',
	name: 'order',
	show: show.order,
	isAddEditPage: false
}

export const albums = {
	inPathName: 'albums',
	name: 'albums',
	show: show.albums
}

export const videos = {
	inPathName: 'videos',
	name: 'videos',
	show: show.videos
}

export const sliders = {
	inPathName: 'sliders',
	name: 'sliders',
	show: show.sliders
}

export const users = {
	inPathName: 'users',
	name: 'users',
	show: show.users
}

export const branches = {
	inPathName: 'branches',
	name: 'branches',
	show: show.branches
}

export const branch = {
	inPathName: 'branch',
	name: 'branch',
	show: show.branch,
	isAddEditPage: true
}

export const subscriptions = {
	inPathName: 'subscriptions',
	name: 'subscriptions',
	show: show.subscriptions
}

export const aboutUs = {
	inPathName: 'about-us',
	name: 'about-us',
	show: show.aboutUs
}

export const companyValues = {
	inPathName: 'company-values',
	name: 'company-values',
	show: show.companyValues
}

export const companyValue = {
	inPathName: 'company-value',
	name: 'company-value',
	show: show.companyValue,
	isAddEditPage: true
}

export const companyHistories = {
	inPathName: 'company-histories',
	name: 'company-histories',
	show: show.companyHistories
}

export const companyHistory = {
	inPathName: 'company-history',
	name: 'company-history',
	show: show.companyHistory,
	isAddEditPage: true
}

export const SEO = {
	inPathName: 'seo',
	name: 'seo',
	show: show.SEO
}