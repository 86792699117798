<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="isInputsChanged || submitLoading"
			class="mb-2"
			@change="updateFields"
		/>

		<!-- inputs -->
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col lg="9" md="8" cols="12" order-md="0" order="1" class="pb-md-0">
				<v-row class="align-center mt-0 mb-2">
					<!-- title -->
					<v-col sm="6" cols="12">
						<v-text-field
							v-model="title"
							:label="$t('inputs.title')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- summary -->
					<v-col sm="6" cols="12">
						<v-text-field
							v-model="summary"
							:label="$t('inputs.summary')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							@change="isInputsChanged = true"
						></v-text-field>
					</v-col>

					<!-- date-of-publication -->
					<v-col lg="3" sm="6" cols="12">
						<!-- date -->
						<v-menu
							ref="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									:label="$t('inputs.date')"
									hide-details
									outlined
									dense
									class="rounded-medium"
									prepend-inner-icon="mdi-calendar"
									clearable
									:loading="loading"
									:disabled="loading || submitLoading"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
								min="1950-01-01"
								@change="$refs.menu.save(date); isInputsChanged = true;"
							></v-date-picker>
						</v-menu>
					</v-col>

					<!-- is-featured -->
					<v-col md="auto" sm="3" cols="auto">
						<v-switch
							v-model="isFeatured"
							:label="$t('inputs.is-featured')"
							hide-details
							class="mt-sm-0 mt-0 pt-0"
							@change="isInputsChanged = true"
						></v-switch>
					</v-col>

					<!-- is home -->
					<v-col v-if="useInHomePage" md="auto" sm="3" cols="auto">
						<v-switch
							v-model="inHomePage"
							:label="$t('inputs.is-in-home-page')"
							hide-details
							class="mt-sm-0 mt-0 pt-0"
							@change="isInputsChanged = true"
						></v-switch>
					</v-col>
				</v-row>
				
				<!-- SEO fields -->
				<template v-if="showSEO">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('SEO-fields')}}
					</div>
					<v-row>
						<!-- SEO keywords -->
						<v-col cols="12">
							<v-text-field
								v-model="SEOkeywords"
								:label="$t('inputs.seo-keywords')"
								hide-details="auto"
								outlined
								dense
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								hint="keywords should separated by (,) eg: keyword1, keyword2"
								@input="SEOkeywords = (SEOkeywords || '').replace(/(\w) /g, '$1, ')"
								@change="isInputsChanged = true"
							></v-text-field>
						</v-col>

						<!-- SEO description -->
						<v-col cols="12">
							<v-textarea
								v-model="SEOdescription"
								:label="$t('inputs.seo-description')"
								outlined
								dense
								auto-grow
								rows="1"
								no-resize
								counter
								:hint="SEOdescription && SEOdescription.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : null"
								persistent-hint
								:color="SEOdescription && SEOdescription.length > 155 ? 'warning' : null"
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								@keypress.enter.prevent
								@change="
									SEOdescription = SEOdescription.replace(/\n/g, ' ');
									isInputsChanged = true;
								"
							></v-textarea>
						</v-col>
					</v-row>
				</template>

				<!-- description -->
				<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
				<text-editor v-model="description" @input="isInputsChanged = true" :loading="loading"/>
			</v-col>

			<v-col cols="auto" class="d-md-block d-none px-1">
				<v-divider vertical/>
			</v-col>

			<!-- drop image -->
			<v-col md cols="12" class="pb-md-0">
				<div class="sticky">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('upload-images')}}
					</div>
					<bee-drop-img
						v-model="mainImage"
						dashed
						border-width="2"
						:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
						radius="11"
						height="250px"
						blurable
						clearable
						:loading="loading"
						:disabled="loading || submitLoading"
						:hide-image-name="false"
						@input="isInputsChanged = true"
					>
						<template v-slot:drag-text>
							<span class="text-body-1 font-weight-medium">
								{{$t('drag-text')}}
							</span>
							<span class="text-caption mb-0">
								{{$t('supported-text')}}
							</span>
						</template>
					</bee-drop-img>
					
					<bee-drop-zone
						v-if="uploadMoreImages"
						v-model="images"
						dashed
						drop-zone-classes="pa-3"
						radius="11"
						border-width="2"
						max-height="250"
						:drop-image-props="{
							width: 70,
							height: 70
						}"
						:clear-icon="$route.params.id ? 'mdi-delete' : 'mdi-close'"
						:clear-color="$route.params.id ? 'red' : null"
						:gap-size="4"
						:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-left="null"
						show-add-btn
						show-drop-menu
						no-drop-replace
						class="mt-3"
						:loading="loading"
						:disabled="loading || submitLoading"
						@remove-image="removeImage"
						@input="isInputsChanged = true"
					>
						<template v-slot:drag-text>
							<p class="mb-0">
								<span class="d-block text-body-1 font-weight-bold">
									{{$t('upload-more-image')}}
								</span>
								<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
							</p>
						</template>
					</bee-drop-zone>
				</div>
			</v-col>
		</v-row>
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: backRouteName }"
			:disabled="loading || submitLoading"
		>
			{{$t('back')}}
		</v-btn>
	</v-form>
</template>

<script>
import moment from 'moment';
import rules from '@/helpers/validation rules'
import { news, SEO } from '@/configs/routes.config';
import { defaultLang, multiLangInputs } from '@/configs/translates.config';
import { useInHomePage, useUploadMoreImages } from '@/configs/global.config';
export default {
	name: 'Product',
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		isSubmitted: false,
		productImages: [],
		lang: defaultLang,
		isInputsChanged: false,
		backRouteName: news.name,
		rules,

		// configs
		showSEO: SEO.show,
		uploadMoreImages: useUploadMoreImages.news,
		useInHomePage,
		multiLangInputs,
		
		// data
		title: null,
		summary: null,
		date: null,
		isFeatured: null,
		inHomePage: null,
		description: null,
		mainImage: null,
		images: [],
		SEOkeywords: null,
		SEOdescription: null,
	}),

	watch: {
		$route(newVal) {
			if (newVal.params.id) {
				this.loading = true;
				this.fetchNews().finally(() => {
					this.isInputsChanged = false;
					this.loading = false;
				});
			}
		}
	},

	methods: {
		submit() {
			this.isSubmitted = true;
			if (this.$refs.form.validate() && this.mainImage) {
				this.submitLoading = true;
				const actionName = this.$route.params.id ? 'update' : 'create';
				// more images
				const images = this.images.filter(c => typeof c !== 'string');

				this.$store.dispatch(`news/${actionName}`, {
					id: this.$route.params.id,
					title: this.title,
					summary: this.summary,
					date: this.date,
					isFeatured: this.isFeatured,
					inHomePage: this.inHomePage,
					content: this.description,
					image: typeof this.mainImage !== 'string' ? this.mainImage : null,
					keywordsSeo: this.SEOkeywords,
					descriptionSeo: this.SEOdescription,
					acceptLanguage: this.lang
				}).then((data) => {
					// next route
					const nextRoute = () => {
						const message =
							actionName === 'update'
								? this.$t('$-is-updated-successfully', { name: this.$t('News.news') })
								: this.$t('$-is-created-successfully', { name: this.$t('News.news') });
						this.$eventBus.$emit('show-snackbar', false, message);

						if (multiLangInputs) {
							this.isInputsChanged = false;
							if (!this.$route.params.id) {
								this.$router.replace({
									name: this.$route.name,
									params: { slug: data.slug, id: data.id }
								});
							}
						} else {
							this.$router.push({ name: news.name });
						}
					}

					// upload the other images
					if (images.length) {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-the-images'))
						this.$store.dispatch('news/uploadImages', {
							id: data.id,
							images,
						}).then(() => {
							nextRoute();
						}).finally(() => {
							this.submitLoading = false;
						})
					} else {
						nextRoute();
					}
				}).finally(() => {
					if (!images.length) {
						this.submitLoading = false;
					}
				})
			}
		},

		// FIXME show dialog to confirm remove
		removeImage(image) {
			if (this.$route.params.id) {
				const imageId = this.productImages.find(c => c.url === image)?.id;
				if (imageId) {
					this.$store.dispatch('news/deleteImage', {
						id: this.$route.params.id,
						imageId: imageId
					})
				}
			}
		},

		updateFields() {
			if (this.$route.params.id) {
				this.$refs.form.reset();
				this.loading = true;
				this.fetchNews().finally(() => {
					this.isInputsChanged = false;
					this.loading = false
				});
			}
		},

		fetchNews() {
			return this.$store.dispatch('news/fetchBySlug', {
				slug: this.$route.params.slug,
				acceptLanguage: this.lang
			}).then((data) => {
				this.title = data.title;
				this.summary = data.summary;
				this.date = data.date ? moment(data.date).format('YYYY-MM-DD') : null;
				this.isFeatured = data.isFeatured;
				this.inHomePage = data.inHomePage;
				this.description = data.content;
				this.mainImage = data.primaryImage.url;
				this.images = data.images.map(c => c.url).filter(c => c !== data.primaryImage.url);
				this.SEOkeywords = data.keywordsSeo ? data.keywordsSeo : '';
				this.SEOdescription = data.descriptionSeo ? data.descriptionSeo : '';

				this.productImages = data.images.filter(c => c !== data.primaryImage.url);
			})
		}
	},

	created() {
		if (this.$route.params.id) {
			this.loading = true;
			this.fetchNews().finally(() => {
				this.loading = false;
			})
		}
	}
};
</script>

<style lang="scss">
</style>