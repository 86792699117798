export const useFileUpload = false;
export const useProductCode = true;
export const useInHomePage = false;
export const useCategoryTree = false;
export const useUploadCategoryImage = true;
export const useUploadFilterImage = false;
export const useOrderingItems = false;
export const useOneBranch = true;
export const useUploadBranchImage = false;
export const useUploadCompanyValuesImage = false;
export const useUploadCompanyHistoriesImage = false;
export const useUploadMoreImages = {
	products: true,
	news: true,
	services: false,
	branches: false,
	companyValues: false,
	companyHistories: false
};
export const useFeatured = {
	sets: false,
	categories: false
};
export const useRestore = {
	sets: false,
	categories: false,
	filters: false,
	keywords: false,
	products: false,
	news: false,
	services: false,
	branches: false
};
export const branchInfo = {
	countryCode: false,
	moneyCode: false,
	address: true,
	openingHours: false,
	location: false
};
export const contacts = {
	email: true,
	phone: true,
	mobile: true,
	whatsapp: true,
	telegram: false,
	telegramChannel: false,
	facebook: true,
	instagram: true,
	twitter: false,
	youtube: false,
	linkedIn: true,
	pinterest: false
};
export const aboutUs = {
	showAbout: true,
	showMission: false,
	showVision: false
};
